import React from 'react';
import dynamic from 'next/dynamic';
import { getLog } from '@aurora/shared-utils/log';
import type { ItemType } from '../../../types/enums';
import { TagViewVariant } from '../../../types/enums';
import type { TagViewFragment } from '../../../types/graphql-types';
import mergeVariantProps from './TagViewDefaultProps';
import type { ItemViewFC, ItemViewVariantFC } from '../../entities/types';
import type { TagViewCommonProps } from './types';

const log = getLog(module);

const TagViewChip = dynamic(() => import('./TagViewChip/TagViewChip'));
const TagViewInline = dynamic(() => import('./TagViewInline/TagViewInline'));
const TagViewTableRow = dynamic(() => import('./TagViewTableRow/TagViewTableRow'));

const variantToComponentMap: Record<
  TagViewVariant,
  ItemViewVariantFC<TagViewFragment, ItemType.TAG, TagViewVariant>
> = {
  chip: TagViewChip,
  inline: TagViewInline,
  tableRow: TagViewTableRow
};

/**
 * The base tag view component that renders the tag view specified by
 * the <code>variant</code> parameter. This component should be used anywhere a tag is rendered.
 *
 * @constructor
 * @author Jonathan Bridges
 */
const TagView: ItemViewFC<TagViewFragment, ItemType.TAG, TagViewVariant> = ({
  entity: tag,
  variant = { type: TagViewVariant.CHIP },
  className
}) => {
  const TagViewComponent = variantToComponentMap[variant?.type];

  if (TagViewComponent) {
    const defaultProps = mergeVariantProps<TagViewCommonProps>(variant);

    return (
      <TagViewComponent
        entity={tag}
        className={className}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...{ ...defaultProps, ...variant.props }}
      />
    );
  }
  log.warn('No registered tag view for variant type [%s]', variant.type);
  return null;
};

export default TagView;
